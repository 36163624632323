/*/////////////////////////// KUNTEYNIR //////////////////////////////////////////////////*/

.kunteynir {
    grid-template-columns: 1fr;
  }
  
  .section--title {
    margin-top: 25px;
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
  }
  
  .section--subtitle {
    font-style: italic;
    font-weight: bold;
    font-size: 30px;
  }